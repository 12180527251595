<template>
  <div class="px-2 py-5">
    <h1 class="title">RIESGO ASOCIADO</h1>
    <div class="block">
      <span> 1.Terapia biológica</span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Tratamiento activo
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.RIE_ASO_P1"
            :native-value="answers.RIE_ASO_P1.RIE_ASO_P1_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P1.RIE_ASO_P1_R1, questions.RIE_ASO_P1)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.RIE_ASO_P1"
            :native-value="answers.RIE_ASO_P1.RIE_ASO_P1_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P1.RIE_ASO_P1_R2, questions.RIE_ASO_P1)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. PASI
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Mayor de 12
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.RIE_ASO_P2"
            :native-value="answers.RIE_ASO_P2.RIE_ASO_P2_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P2.RIE_ASO_P2_R1, questions.RIE_ASO_P2)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.RIE_ASO_P2"
            :native-value="answers.RIE_ASO_P2.RIE_ASO_P2_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P2.RIE_ASO_P2_R2, questions.RIE_ASO_P2)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. DLQI
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Mayor de 10
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.RIE_ASO_P3"
            :native-value="answers.RIE_ASO_P3.RIE_ASO_P3_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P3.RIE_ASO_P3_R1, questions.RIE_ASO_P3)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.RIE_ASO_P3"
            :native-value="answers.RIE_ASO_P3.RIE_ASO_P3_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P3.RIE_ASO_P3_R2, questions.RIE_ASO_P3)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>4. Terapia sistémica previa </span>

      <div class="columns">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Tratamiento previo con fallo terapéutico
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.RIE_ASO_P4"
            :native-value="answers.RIE_ASO_P4.RIE_ASO_P4_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P4.RIE_ASO_P4_R1, questions.RIE_ASO_P4)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.RIE_ASO_P4"
            :native-value="answers.RIE_ASO_P4.RIE_ASO_P4_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P4.RIE_ASO_P4_R2, questions.RIE_ASO_P4)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        5. Fototerapia
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Tratamiento previo sin mejoría clínica
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.RIE_ASO_P5"
            :native-value="answers.RIE_ASO_P5.RIE_ASO_P5_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P5.RIE_ASO_P5_R1, questions.RIE_ASO_P5)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.RIE_ASO_P5"
            :native-value="answers.RIE_ASO_P5.RIE_ASO_P5_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P5.RIE_ASO_P5_R2, questions.RIE_ASO_P5)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        6. Sindrome metabolico
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Presencia de sindrome metabolico completo
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.RIE_ASO_P6"
            :native-value="answers.RIE_ASO_P6.RIE_ASO_P6_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P6.RIE_ASO_P6_R1, questions.RIE_ASO_P6)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.RIE_ASO_P6"
            :native-value="answers.RIE_ASO_P6.RIE_ASO_P6_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P6.RIE_ASO_P6_R2, questions.RIE_ASO_P6)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        7. Enfermedad cardiovascular
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Evento cardio cerebro vascular previo
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.RIE_ASO_P7"
            :native-value="answers.RIE_ASO_P7.RIE_ASO_P7_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P7.RIE_ASO_P7_R1, questions.RIE_ASO_P7)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.RIE_ASO_P7"
            :native-value="answers.RIE_ASO_P7.RIE_ASO_P7_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P7.RIE_ASO_P7_R2, questions.RIE_ASO_P7)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />
    <div class="block">
      <span>
        8. IMC
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Mayor de 30
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.RIE_ASO_P8"
            :native-value="answers.RIE_ASO_P8.RIE_ASO_P8_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P8.RIE_ASO_P8_R1, questions.RIE_ASO_P8)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.RIE_ASO_P8"
            :native-value="answers.RIE_ASO_P8.RIE_ASO_P8_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P8.RIE_ASO_P8_R2, questions.RIE_ASO_P8)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />
    <div class="block">
      <span>
        9. Diabetes
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Dificil control y/o compromiso de organo
          blanco
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.RIE_ASO_P9"
            :native-value="answers.RIE_ASO_P9.RIE_ASO_P9_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P9.RIE_ASO_P9_R1, questions.RIE_ASO_P9)
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.RIE_ASO_P9"
            :native-value="answers.RIE_ASO_P9.RIE_ASO_P9_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(answers.RIE_ASO_P9.RIE_ASO_P9_R2, questions.RIE_ASO_P9)
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />
    <div class="block">
      <span>
        10. HTA
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - HTA no controlada Y/o compromiso de organo
          blanco
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.RIE_ASO_P10"
            :native-value="answers.RIE_ASO_P10.RIE_ASO_P10_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P10.RIE_ASO_P10_R1,
                questions.RIE_ASO_P10
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.RIE_ASO_P10"
            :native-value="answers.RIE_ASO_P10.RIE_ASO_P10_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P10.RIE_ASO_P10_R2,
                questions.RIE_ASO_P10
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        11. Depresión
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Antecedente de depresión mayor
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.RIE_ASO_P11"
            :native-value="answers.RIE_ASO_P11.RIE_ASO_P11_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P11.RIE_ASO_P11_R1,
                questions.RIE_ASO_P11
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.RIE_ASO_P11"
            :native-value="answers.RIE_ASO_P11.RIE_ASO_P11_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P11.RIE_ASO_P11_R2,
                questions.RIE_ASO_P11
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        12. PASI
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Entre 10-12 con terapia sistemica de
          primera linea
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.RIE_ASO_P12"
            :native-value="answers.RIE_ASO_P12.RIE_ASO_P12_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P12.RIE_ASO_P12_R1,
                questions.RIE_ASO_P12
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.RIE_ASO_P12"
            :native-value="answers.RIE_ASO_P12.RIE_ASO_P12_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P12.RIE_ASO_P12_R2,
                questions.RIE_ASO_P12
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        13. Depresión
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Depresion leve a moderada
        </div>
        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.RIE_ASO_P13"
            :native-value="answers.RIE_ASO_P13.RIE_ASO_P13_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P13.RIE_ASO_P13_R1,
                questions.RIE_ASO_P13
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.RIE_ASO_P13"
            :native-value="answers.RIE_ASO_P13.RIE_ASO_P13_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P13.RIE_ASO_P13_R2,
                questions.RIE_ASO_P13
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        14. HTA
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - HTA Controlada
        </div>
        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.RIE_ASO_P14"
            :native-value="answers.RIE_ASO_P14.RIE_ASO_P14_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P14.RIE_ASO_P14_R1,
                questions.RIE_ASO_P14
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.RIE_ASO_P14"
            :native-value="answers.RIE_ASO_P14.RIE_ASO_P14_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P14.RIE_ASO_P14_R2,
                questions.RIE_ASO_P14
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        15. Diabetes
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Diabetes controlada
        </div>
        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.RIE_ASO_P15"
            :native-value="answers.RIE_ASO_P15.RIE_ASO_P15_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P15.RIE_ASO_P15_R1,
                questions.RIE_ASO_P15
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.RIE_ASO_P15"
            :native-value="answers.RIE_ASO_P15.RIE_ASO_P15_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P15.RIE_ASO_P15_R2,
                questions.RIE_ASO_P15
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        16. IMC
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>MEDICION</strong> - Entre 25 y 29
        </div>
        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.RIE_ASO_P16"
            :native-value="answers.RIE_ASO_P16.RIE_ASO_P16_R1"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P16.RIE_ASO_P16_R1,
                questions.RIE_ASO_P16
              )
            "
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.RIE_ASO_P16"
            :native-value="answers.RIE_ASO_P16.RIE_ASO_P16_R2"
            :disabled="queryMode"
            @input="
              saveAnswer(
                answers.RIE_ASO_P16.RIE_ASO_P16_R2,
                questions.RIE_ASO_P16
              )
            "
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <div class="block has-text-centered title" v-if="!queryMode">
      Resultado : {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="block has-text-centered title" v-if="queryMode">
      Resultado : {{ result }}
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { RIE_ASO as questions } from "../../../data/questions";
import { RIE_ASO as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: null,
      answerTwo: null,
      answerThree: null,
      answerFour: null,
      answerFive: null,
      answerSix: null,
      answerSeven: null,
      answerEight: null,
      answerNine: null,
      answerTen: null,
      answerEleven: null,
      answerTwelve: null,
      answerThirteen: null,
      answerFouthteen: null,
      answerFifteen: null,
      answerSixteen: null,
      answersArray: [],
      restoreData: {},
      queryMode: false,
      result: "",
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "Riesgo-asociadoList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
      this.answerSix = this.$route.params.temporalData.answerSix;
      this.answerSeven = this.$route.params.temporalData.answerSeven;
      this.answerEight = this.$route.params.temporalData.answerEight;
      this.answerNine = this.$route.params.temporalData.answerNine;
      this.answerTen = this.$route.params.temporalData.answerTen;
      this.answerEleven = this.$route.params.temporalData.answerEleven;
      this.answerTwelve = this.$route.params.temporalData.answerTwelve;
      this.answerThirteen = this.$route.params.temporalData.answerThirteen;
      this.answerFouthteen = this.$route.params.temporalData.answerFouthteen;
      this.answerFifteen = this.$route.params.temporalData.answerFifteen;
      this.answerSixteen = this.$route.params.temporalData.answerSixteen;
      this.answersArray = this.$route.params.temporalData.answersArray;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      let text = "Nulo";
      let hight = [];
      let low = [];

      this.answersArray.forEach((item) => {
        if (item.type == "Hight" && item.value == true) {
          hight.push(item);
        } else if (item.type == "Low" && item.value == true) {
          low.push(item);
        }
      });

      if (hight.length >= 2 || (hight.length == 1 && low.length >= 2)) {
        text = "Alto";
      } else if (low.length >= 1) {
        text = "Intermedio";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.answerOne == null ||
        this.answerTwo == null ||
        this.answerThree == null ||
        this.answerFour == null ||
        this.answerFive == null ||
        this.answerSix == null ||
        this.answerSeven == null ||
        this.answerEight == null ||
        this.answerNine == null ||
        this.answerTen == null ||
        this.answerEleven == null ||
        this.answerTwelve == null ||
        this.answerThirteen == null ||
        this.answerFouthteen == null ||
        this.answerFifteen == null ||
        this.answerSixteen == null
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = null;
      this.answerTwo = null;
      this.answerThree = null;
      this.answerFour = null;
      this.answerFive = null;
      this.answerSix = null;
      this.answerSeven = null;
      this.answerEight = null;
      this.answerNine = null;
      this.answerTen = null;
      this.answerEleven = null;
      this.answerTwelve = null;
      this.answerThirteen = null;
      this.answerFouthteen = null;
      this.answerFifteen = null;
      this.answerSixteen = null;
      this.answersArray = [];
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;
      this.restoreData.answerSix = this.answerSix;
      this.restoreData.answerSeven = this.answerSeven;
      this.restoreData.answerEight = this.answerEight;
      this.restoreData.answerNine = this.answerNine;
      this.restoreData.answerTen = this.answerTen;
      this.restoreData.answerEleven = this.answerEleven;
      this.restoreData.answerTwelve = this.answerTwelve;
      this.restoreData.answerThirteen = this.answerThirteen;
      this.restoreData.answerFouthteen = this.answerFouthteen;
      this.restoreData.answerFifteen = this.answerFifteen;
      this.restoreData.answerSixteen = this.answerSixteen;
      this.restoreData.answersArray = this.answersArray;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog(
        "Se limpiaron los datos de  la encuesta RIESGO ASOCIADO."
      );
    },
    saveAnswer(data, key) {
      this.answersArray.forEach((item, index) => {
        if (item.key == key) {
          this.answersArray.splice(index, 1);
        }
      });

      this.answersArray.push({
        type: data.type,
        value: data.value,
        key: key,
      });

      this.saveTermporalSulrvey();
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "RIE_ASO",
          result: "",
          appreciation: String(this.score),
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;
      let question6 = null;
      let question7 = null;
      let question8 = null;
      let question9 = null;
      let question10 = null;
      let question11 = null;
      let question12 = null;
      let question13 = null;
      let question14 = null;
      let question15 = null;
      let question16 = null;

      Object.keys(this.answers[questions.RIE_ASO_P1]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.RIE_ASO_P1,
            answerCode: key,
            value: this.answerOne.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P2]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.RIE_ASO_P2,
            answerCode: key,
            value: this.answerTwo.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P3]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.RIE_ASO_P3,
            answerCode: key,
            value: this.answerThree.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P4]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.RIE_ASO_P4,
            answerCode: key,
            value: this.answerFour.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P5]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.RIE_ASO_P5,
            answerCode: key,
            value: this.answerFive.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P6]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P6][key] == this.answerSix) {
          question6 = {
            questionCode: questions.RIE_ASO_P6,
            answerCode: key,
            value: this.answerSix.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P7]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P7][key] == this.answerSeven) {
          question7 = {
            questionCode: questions.RIE_ASO_P7,
            answerCode: key,
            value: this.answerSeven.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P8]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P8][key] == this.answerEight) {
          question8 = {
            questionCode: questions.RIE_ASO_P8,
            answerCode: key,
            value: this.answerEight.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P9]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P9][key] == this.answerNine) {
          question9 = {
            questionCode: questions.RIE_ASO_P9,
            answerCode: key,
            value: this.answerNine.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P10]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P10][key] == this.answerTen) {
          question10 = {
            questionCode: questions.RIE_ASO_P10,
            answerCode: key,
            value: this.answerTen.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P11]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P11][key] == this.answerEleven) {
          question11 = {
            questionCode: questions.RIE_ASO_P11,
            answerCode: key,
            value: this.answerEleven.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P12]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P12][key] == this.answerTwelve) {
          question12 = {
            questionCode: questions.RIE_ASO_P12,
            answerCode: key,
            value: this.answerTwelve.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P13]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P13][key] == this.answerThirteen) {
          question13 = {
            questionCode: questions.RIE_ASO_P13,
            answerCode: key,
            value: this.answerThirteen.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P14]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P14][key] == this.answerFouthteen) {
          question14 = {
            questionCode: questions.RIE_ASO_P14,
            answerCode: key,
            value: this.answerFouthteen.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P15]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P15][key] == this.answerFifteen) {
          question15 = {
            questionCode: questions.RIE_ASO_P15,
            answerCode: key,
            value: this.answerFifteen.value == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.RIE_ASO_P16]).forEach((key) => {
        if (this.answers[questions.RIE_ASO_P16][key] == this.answerSixteen) {
          question16 = {
            questionCode: questions.RIE_ASO_P16,
            answerCode: key,
            value: this.answerSixteen.value == true ? "Si" : "No",
          };
        }
      });

      return [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
        question8,
        question9,
        question10,
        question11,
        question12,
        question13,
        question14,
        question15,
        question16,
      ];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;
      this.answerSix = this.restoreData.answerSix;
      this.answerSeven = this.restoreData.answerSeven;
      this.answerEight = this.restoreData.answerEight;
      this.answerNine = this.restoreData.answerNine;
      this.answerTen = this.restoreData.answerTen;
      this.answerEleven = this.restoreData.answerEleven;
      this.answerTwelve = this.restoreData.answerTwelve;
      this.answerThirteen = this.restoreData.answerThirteen;
      this.answerFouthteen = this.restoreData.answerFouthteen;
      this.answerFifteen = this.restoreData.answerFifteen;
      this.answerSixteen = this.restoreData.answerSixteen;
      this.answersArray = this.restoreData.answersArray;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "RIESGO ASOSICADO",
          route: "Riesgo-asociado",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
          answerSix: this.answerSix,
          answerSeven: this.answerSeven,
          answerEight: this.answerEight,
          answerNine: this.answerNine,
          answerTen: this.answerTen,
          answerEleven: this.answerEleven,
          answerTwelve: this.answerTwelve,
          answerThirteen: this.answerThirteen,
          answerFouthteen: this.answerFouthteen,
          answerFifteen: this.answerFifteen,
          answerSixteen: this.answerSixteen,
          answersArray: this.answersArray,
        },
      });
    },
    setEvaluationAnswers(data) {
      this.result = data.appreciation;
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "RIE_ASO_P1") {
          this.answerOne = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P2") {
          this.answerTwo = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P3") {
          this.answerThree = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P4") {
          this.answerFour = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P5") {
          this.answerFive = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P6") {
          this.answerSix = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P7") {
          this.answerSeven = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P8") {
          this.answerEight = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P9") {
          this.answerNine = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P10") {
          this.answerTen = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P11") {
          this.answerEleven = {
            value: survey.value == "Si" ? true : false,
            type: "Hight",
          };
        }

        if (survey.question.code == "RIE_ASO_P12") {
          this.answerTwelve = {
            value: survey.value == "Si" ? true : false,
            type: "Low",
          };
        }

        if (survey.question.code == "RIE_ASO_P13") {
          this.answerThirteen = {
            value: survey.value == "Si" ? true : false,
            type: "Low",
          };
        }

        if (survey.question.code == "RIE_ASO_P14") {
          this.answerFouthteen = {
            value: survey.value == "Si" ? true : false,
            type: "Low",
          };
        }

        if (survey.question.code == "RIE_ASO_P15") {
          this.answerFifteen = {
            value: survey.value == "Si" ? true : false,
            type: "Low",
          };
        }

        if (survey.question.code == "RIE_ASO_P16") {
          this.answerSixteen = {
            value: survey.value == "Si" ? true : false,
            type: "Low",
          };
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.RIE_ASO_P1.RIE_ASO_P1_R2;
      this.answerTwo = this.answers.RIE_ASO_P2.RIE_ASO_P2_R2;
      this.answerThree = this.answers.RIE_ASO_P3.RIE_ASO_P3_R2;
      this.answerFour = this.answers.RIE_ASO_P4.RIE_ASO_P4_R2;
      this.answerFive = this.answers.RIE_ASO_P5.RIE_ASO_P5_R2;
      this.answerSix = this.answers.RIE_ASO_P6.RIE_ASO_P6_R2;
      this.answerSeven = this.answers.RIE_ASO_P7.RIE_ASO_P7_R2;
      this.answerEight = this.answers.RIE_ASO_P8.RIE_ASO_P8_R2;
      this.answerNine = this.answers.RIE_ASO_P9.RIE_ASO_P9_R2;
      this.answerTen = this.answers.RIE_ASO_P10.RIE_ASO_P10_R2;
      this.answerEleven = this.answers.RIE_ASO_P11.RIE_ASO_P11_R2;
      this.answerTwelve = this.answers.RIE_ASO_P12.RIE_ASO_P12_R2;
      this.answerThirteen = this.answers.RIE_ASO_P13.RIE_ASO_P13_R2;
      this.answerFouthteen = this.answers.RIE_ASO_P14.RIE_ASO_P14_R2;
      this.answerFifteen = this.answers.RIE_ASO_P15.RIE_ASO_P15_R2;
      this.answerSixteen = this.answers.RIE_ASO_P16.RIE_ASO_P16_R2;
      this.answersArray = [];
    },
  },
};
</script>
